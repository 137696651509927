import React, { useRef } from "react"
import { faGlobe, faPrint } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import PropTypes from "prop-types"
import ReactToPrint from "react-to-print"
import SEO from "components/seo"
import { graphql } from "gatsby"

export default function KullanimKilavuzu({ data }) {
  const componentRef = useRef()
  const toc = data.mdx.tableOfContents.items
  const sidebarItems = toc.map(item => (
    <>
      <li key={item.url} className="nav-item section-title">
        <a className="nav-link scrollto" href={item.url}>
          <span className="theme-icon-holder mr-2">
            {" "}
            <FontAwesomeIcon icon={faGlobe} />
          </span>
          {toc.indexOf(item) + 1}. {item.title}
        </a>
      </li>
      {item.items &&
        item.items.map(subItem => (
          <li key={subItem.url} className="nav-item">
            <a className="nav-link scrollto" href={subItem.url}>
              {toc.indexOf(item) + 1}. {item.items.indexOf(subItem) + 1}.{" "}
              {subItem.title}
            </a>
          </li>
        ))}
    </>
  ))
  return (
    <Layout>
      <SEO
        title="Kütüphane Programı - Kullanım Kılavuzu - Tusunami Kütüphane Takip"
        description="Tusunami Kütüphane Takip isimli yazılımda sorabileceğiniz tüm sorular bu sayfada sıralanmıştır. Bu sayede kütüphane programını rahatça kullanabilirsiniz."
        pathname="https://www.kodvizit.com/kutuphane-programi/kullanim-kilavuzu"
        datePublished="2017-02-09T22:22:55+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          {
            path: "https://www.kodvizit.com/kutuphane-programi",
            name: "Kütüphane Programı",
          },
        ]}
      />
      <div className="docs-wrapper container-fluid">
        <div className="row">
          <div className="col-lg-3 d-xl-block d-none">
            <div className="sticky-top" style={{ top: 40 }}>
              <div
                className="docs-sidebar sidebar-visible d-none d-xl-block"
                style={{ maxHeight: "95vh" }}
              >
                <nav id="docs-nav" className="docs-nav navbar">
                  <ul className="section-items list-unstyled nav flex-column pb-3">
                    {sidebarItems}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="docs-content" ref={componentRef}>
              <div className="container">
                <ReactToPrint
                  trigger={() => (
                    <div className="d-none d-lg-flex justify-content-end m-0">
                      <a
                        className=""
                        href="#"
                        title="Kullanım Kılavuzunu Yazdır"
                      >
                        <FontAwesomeIcon icon={faPrint}></FontAwesomeIcon>
                      </a>
                    </div>
                  )}
                  content={() => componentRef.current}
                />
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    mdx(frontmatter: { program: { eq: "kutuphane" } }) {
      body
      tableOfContents
    }
  }
`

KullanimKilavuzu.propTypes = {
  data: PropTypes.object,
}
